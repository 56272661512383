.ConfirmationDialog__content {
  text-align: center;
  padding: 1em;
  button {
    margin-top: 1em;
    margin-left: 2em;
    cursor: pointer;
    &.ConfirmationDialog__disabled {
      background: rgba(85, 85, 85, 0.2);
      backdrop-filter: blur(54.3656px);
    }
  }
}