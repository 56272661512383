.VoucherCosts {

  .Row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    &.MarginBottom2 {
      margin-bottom: 2em;
    }

    &.MarginBottom1 {
      margin-bottom: 1em;
    }

    .Chevron {
      cursor: pointer;
    }

    .CostHeader {
      margin-top: 0;
      font-size: 70px;
      line-height: 84px;
      padding: 0;
      font-weight: 600;
    }

    .CostSubHeader {
      font-size: 24px;
      line-height: 29px;
      padding: 0;
      font-weight: bold;
    }
  }
}