@import "../../../styles/config";

.XXcrementor {
  width: 34px;
  height: 34px;

  & > circle {
    fill: #f8f8f8;
    stroke-width: 1px;
    stroke: #eaeaea;
  }
  & > line {
    stroke-width: 4px;
    stroke: $colorPrimaryDark;
  }
}
