@import "../../styles/config";

.Purchase {
  display: flex;
  justify-content: space-between;

  &__count,
  &__amount {
    & > p {
      font-size: $fontSizeSmall;
      color: $colorText;
      margin-bottom: 9px;
    }
  }

  &__amount {
    width: 50%;
    text-align: right;

    & > p:nth-child(2) {
      font-size: 20px;
      font-weight: 500;
      line-height: 1.35;
      letter-spacing: 0.4px;
      margin-top: 12px;
    }
  }
}
