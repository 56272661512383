.OrderSubscribedService__content__purchase {
  .FormButton {
    cursor: pointer;
  }
  
  .ModificationChanges {
    margin-bottom: 1em;
    font-size: 14px;
    font-weight: 900;
  }

  .ModifyButtons {
    display: flex;
    max-width: 1110px;
    justify-content: space-between;

    .CancelButton {
      width: auto;
      color: #FF4848;
      font-size: 16px;
      margin-left: 0.75em;
      justify-content: flex-start;
    }
  }
}
