@import "../../styles/config";

.View.Account {
  @media screen and (min-width: $breakPointXSmallUp) {
    padding: 26px 55px; // for separators
  }
}
.Account {
  &__current {
    @media screen and (min-width: 820px) {
      display: flex;
    }
  }
  &__current,
  &__prospects {
    padding: 27px 25px 43px 25px;
    width: 100%;
  }
  &__subtitle {
    margin-bottom: 25px;
    color: $colorText;
  }
  &__serviceList {
    display: flex;
    width: 100%;
    flex-flow: row wrap;
    & > div.SubscribedServiceCard {
      margin-bottom: 32px;
      margin-right: 32px;
    }
  }
}

#Account__public_services_menu {
  display: flex;
  margin-bottom: 14px;
  > li {
    margin-right: 0.5em;
  }
}

.Account__public_services_menu_link {
  color: $colorPrimary;
  cursor: pointer;
}
