@import "../../styles/config";

.SubscribedServiceCard {
  display: inline-block;
  width: 353px;

  &__disabled {
    padding: 0;
    background-color: $colorError;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    background: $colorError;
    font-size: $fontSizeLarge;
    font-weight: bold;
    color: $colorWhite;
    border: none;
    border-radius: 10px 10px 0 0;
  }

  &__ContentOuter {
    border: solid 1px $colorLighter;
  }

  &__ContentInner {
    &--Top {
      margin: 31px 14px 0 22px;
      position: relative;
      padding: 0 8px 10px 8px;
      border-bottom: dotted 1px #4a4a4a;
      min-height: 117px;
    }
    &--Bottom {
      margin-left: 14px;
      padding: 10px 8px;
    }
    &__icon {
      width: 30px;
      height: 35px;
      position: absolute;
      top: 0;
      right: 0;
    }
    &__Type {
      color: $colorText;
      font-size: $fontSizeSmall;
      letter-spacing: 0.3px;
      text-transform: uppercase;
    }
    &__title {
      font-size: 20px;
      line-height: 1.1;
      letter-spacing: 0.4px;
      font-weight: 500;
      color: $colorText;
      width: 265px;
      margin-bottom: 5px;
    }
    &__subitems {
      width: 290px;
      max-height: 36px;
      overflow: hidden;
      font-size: $fontSizeSmall;
      line-height: 1.29;
      color: $colorText;

      &__text {
        font-weight: bold;
        margin-right: 0.25em;
      }
    }
    &__subscription {
      font-size: $fontSizeSmall;
      color: $colorText;
      line-height: 1.29;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    color: $colorWhite;
    cursor: pointer;
    font-size: $fontSizeLarge;
    font-weight: bold;
    border: none;
    border-radius: 0 0 10px 10px;
    background: $gradientLightDark;

    &--gold {
      background: $secondaryLightDark;
    }

    &--disabled {
      cursor: auto;
      background: $colorDisabledBackground;
    }
  }

  &__actionLink {
    display: block;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
}
