@import "../../styles/config";

.Checkbox {
  display: inline-block;

  & button {
    margin-right: 10px;
    vertical-align: middle;
  }

  & > label > span {
    display: inline-block;
    vertical-align: middle;
  }

  &__unchecked {
    width: 24px;
    height: 24px;
    & path:nth-child(1) {
      fill: $colorWhite;
    }
    & path:nth-child(2) {
      fill: $colorPrimaryDark;
    }
  }

  &__invalid {
    & path:nth-child(2) {
      fill: $colorError;
    }
  }
}
