@import "../../styles/config";

.View {
  padding: 13px 34px;
  @media screen and (min-width: $breakPointXSmallUp) {
    padding: 26px 67px;
  }
}

.H1 {
  font-size: $fontSizeLarge;
  font-weight: bold;
  line-height: $textLineHeight;
}

.H2 {
  font-size: $fontSizeSmall;
  font-weight: bold;
  line-height: $textLineHeight;
}

.FormText {
  font-size: $fontSize;
  margin-top: 4px;
  margin-bottom: 16px;
}

.LinkButton,
.InvisibleButton {
  padding: 0;
  margin: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
}

.LinkButton:disabled,
.InvisibleButton:disabled {
  color: #4a4a4a;
  cursor: not-allowed;
}

.LinkButton {
  color: $colorPrimaryDark;
  line-height: 2;
  font-weight: bold;
}

.FormButton {
  padding: 0;
  margin: 0;
  border: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 335px;
  height: 46px;
  background-image: $gradientLightDark;
  color: $colorWhite;
  font-size: $fontSize;
  font-weight: bold;
  border-radius: 23px;
}
.FormButton:disabled {
  background: rgba(85, 85, 85, 0.2);
  backdrop-filter: blur(54.3656px);
}

.P {
  padding: 1em 0;
  font-size: $fontSizeSmall;
  line-height: $textLineHeight;
  max-width: $maxTextColumn;
  color: $colorText;
}

.Page {
  min-height: calc(100vh - 432px); // 145px header, 287px footer

  @media screen and (min-width: $breakPointXSmallUp) {
    min-height: calc(100vh - 330px); // 171px header, 159 px footer
  }
}

.A,
.A:visited {
  color: $colorPrimary;
  font-weight: bold;
  cursor: pointer;
}
