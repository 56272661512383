/* Colors */
$colorPrimary: #4a90e2;
$colorPrimaryDark: #0098d8;
$colorPrimaryLight: #09c8ec;
$colorPrimaryLighter: #f1fafd;
$colorPrimaryBackground: #085171;
$colorPrimary: $colorPrimaryDark;
$colorPrimaryDarker: darken($colorPrimaryDark, 10%);
$colorDark: #7c7c7c;
$colorDarker: #6d6d6d;
$colorLight: #9b9b9b;
$colorLighter: #d8d8d8;
$colorError: #d0021b;
$colorWhite: white;
$colorBlack: black;
$colorText: #555555;
$colorSuccess: #7ed321;
$colorSecondaryLight: #fde43c;
$colorSecondaryDark: #ffc321;

$colorDisabledBackground: $colorDarker;
$backgroundColor: $colorWhite;
$backgroundColorDark: darken($backgroundColor, 5%);
$backgroundColorExtraDark: darken($backgroundColorDark, 5%);
$backgroundColorLight: #f1f2f3;
$backgroundColorExtraLight: lighten($backgroundColorLight, 15%);

$gradientDarkLight: linear-gradient(
  98deg,
  $colorPrimaryDark,
  $colorPrimaryLight
);
$gradientLightDark: linear-gradient(
  98deg,
  $colorPrimaryLight,
  $colorPrimaryDark
);

$secondaryLightDark: linear-gradient(
  94deg,
  $colorSecondaryLight,
  $colorSecondaryDark
);
$secondaryDarkLight: linear-gradient(
  94deg,
  $colorSecondaryDark,
  $colorSecondaryLight
);

/* Type */
$fontFamily: "Fira Sans", sans-serif;
$fontSizeExtraLarge: 49px;
$fontSizeLarge: 18px;
$fontSize: 16px;
$fontSizeSmall: 14px;
$fontSizeSmaller: 10px;
$textLineHeight: 1.44;

/* Border */
$borderThick: 4px;
$borderRadiusSmall: 3px;

/* Breakpoints */
$breakPointXSmallUp: 450px;
$breakPointSmallUp: 767px;

/* Text width */
$maxTextColumn: 482px;
