.SendVoucher {
  input {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
  }

  .SecondaryContainer {
    padding: 1em 1em 1em 4em;
    background: rgba(85, 85, 85, 0.05);

    .Active {
      font-weight: bold;
    }
    span,
    button {
      font-size: 16px;
      font-weight: bold;
      margin-right: 12px;
    }

    .Label {
      display: block;
      margin-bottom: 1em;
    }

    .Text {
      color: rgba(85, 85, 85, 0.5);
      font-weight: normal;
    }
    .Link {
      color: #0098d8;
    }

    .SendVoucherContainer {
      margin-top: 16px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
      grid-gap: 0 2em;

      .SubLabel {
        font-style: italic;
        color: #979797;
      }

      .MarginTop {
        margin-top: 1.3em;
      }

      .EvenMoreMarginTop {
        margin-top: 1.8em;
      }

      .ParkingInformation p {
        margin-top: 0.4em;
      }

      .react-datepicker-wrapper {
        width: 100%;
      }
    }

    .SendButton {
      cursor: pointer;
      margin-top: 1.5em;
      text-align: center;
    }
  }
}
