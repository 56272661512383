@import "./styles/config";

body {
  margin: 0;
  padding: 0;
  font-family: $fontFamily;

  button,input {
    font-family: $fontFamily;
  }

  .App {
    min-width: fit-content;
    width: auto;
  }
}
