@import "../../styles/config";

.BackTo {
  padding-top: 22px;
  padding-bottom: 28px;

  & a,
  & a:visited {
    text-decoration: none;
    color: $colorPrimaryDark;
  }
}
