.Voucher {
  max-width: 1280px;
  .H1 {
    margin-top: 1em;
    margin-bottom: 1em;
    color: #555;
  }

  .GiveVoucherContainer {
    @media screen and (min-width: 820px) {
      display: flex;
    }

    .VoucherCard {
      margin-bottom: 1em;
    }

    .Left {
      max-width: 380px;
      margin-right: 2em;
    }
  
    .Right {
      flex-grow: 1;
    }
  }

  .UsedVouchersContainer {
    display: flex;

    .Left {
      flex-grow: 1;
      max-width: 80%;
      margin-right: 2em;
    }

  }

}