@import "../../styles/config";

.Counter {
  display: inline-block;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__button {
    padding: 0;
    border: none;
    background: transparent;
    cursor: pointer;
  }

  &__input {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -0.1px;
    color: $colorText;
    text-align: center;
    border: none;
    margin: 0 0.5em;
  }
}
