@import "../../styles/config";

.OrderSubscribedService {
  &__content {
    display: flex;
    color: $colorText;
    max-width: 1170px;

    & h1 {
      margin-bottom: 28px;
    }
    &__icon {
      width: 44px;
      height: 51px;
      margin-right: 20px;
    }
    &__description {
      line-height: $textLineHeight;
      margin-top: 22px;
      margin-bottom: 28px;
      max-width: 731px;

      &__injected {
        margin-top: 22px;

        & strong {
          font-weight: bold;
        }
        & td {
          padding: 0 8px 0 0;
        }
      }
      &__textblock {
        min-height: 1em;
      }
    }
    &__purchase {
      max-width: 1170px;
      min-width: 500px;
      margin-top: 28px;

      .current__amount {
        font-size: 14px;
        margin-bottom: 1em;
        font-weight: 900;
      }

      & > button {
        margin-top: 28px;
      }

      .Purchase {
        max-width: 335px;
      }
    }
  }
  &__datarow {
    line-height: $textLineHeight;
    max-width: 637px;
  }
  &__dataTitle {
    font-weight: bold;
    margin-right: 0.25em;
  }
}
