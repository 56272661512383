@import "../../styles/config";

// Header styles
.Header {
  background-image: $gradientDarkLight;
  display: flex;
  flex-flow: column nowrap;

  @media screen and (min-width: $breakPointXSmallUp) {
    flex-flow: row nowrap;
  }

  &__facade {
    overflow: hidden;
    text-align: center;

    @media screen and (min-width: $breakPointXSmallUp) {
      flex: 0 0 328px;
      text-align: left;
    }

    &--margin {
      margin-bottom: 0.5em;

      @media screen and (min-width: $breakPointXSmallUp) {
        margin-bottom: 0;
      }
    }
  }
  &__logo {
    width: 122px;
    height: 46px;
  }
  &__title {
    font-size: $fontSizeExtraLarge;
    font-weight: bold;
    line-height: $textLineHeight;
    text-transform: uppercase;
    color: $colorWhite;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @media screen and (min-width: $breakPointXSmallUp) {
      font-size: 50px;
      line-height: 1.41;
    }
  }
  &__content {
    width: 100%;
  }
}

// Navigation bar
@media screen and (min-width: 805px) {
  div.View.Header {
    ul.Container {
      .Logout {
        margin-left: auto;
        margin-right: 0;
        text-transform: none;
      }
    }
  }
}

div.View.Header {
  ul.Container {
    display: flex;
    position: relative;
    top: 88px;
    color: $colorWhite;
    font-size: 14px;
    font-weight: bold;
    
    .Active, .Link {
      cursor: pointer;
      margin-right: 20px;
      text-transform: uppercase;
    }

    .Active {
      border-bottom: 3px solid $colorWhite;
      padding-bottom: 0.5em;
    }
  }
}

.LogoutButton {
    cursor: pointer;
    svg {
      margin-right: 0.5em;
      vertical-align: bottom;
    }
}