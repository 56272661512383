@import "../../../styles/config";

.UnassignedUser {
  .Input {
    & > input {
      height: 35px;
      background: #fafafa;
      border: 1px solid #999999;
      color: #4a4a4a;
    }
    & > input::placeholder {
      color: #cecece;
    }
  }
  &__field {
    padding: 14px 23px 0 23px;
    &__name.Input {
      width: 178px;
    }
    &__phone.Input {
      width: 238px;
    }
    &__maxActiveVehicles.Input {
      width: 100px;
    }
  }
  &__action {
    vertical-align: top;
  }
}
