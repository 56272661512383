@import "../../styles/config";

.Footer {
  color: $colorWhite;
  background: $colorPrimaryBackground;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  &__select {
    &--mobile {
      display: inline;

      @media screen and (min-width: $breakPointSmallUp) {
        display: none;
      }
    }
    &--desktop {
      display: none;

      @media screen and (min-width: $breakPointSmallUp) {
        display: inline;
      }
    }
  }

  &__address {
    font-size: $fontSizeSmaller;
    margin-top: 29px;

    @media screen and (min-width: $breakPointSmallUp) {
      font-size: $fontSizeSmall;
    }
  }

  &__info {
    text-align: center;
    max-width: 220px;
    @media screen and (min-width: $breakPointSmallUp) {
      text-align: left;
      max-width: none;
    }
  }

  &__support {
    &__title {
      margin: 0;
      font-weight: bold;
    }
    &__email {
      margin: 0 0 1em 0;
    }
  }

  &__apps {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;

    & > div {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: center;
    }
    @media screen and (min-width: $breakPointSmallUp) {
      & > div {
        flex-flow: row nowrap;
      }
    }
  }

  &__appslogan {
    margin: 8px 0;
    font-size: $fontSizeLarge;
  }

  &__apple {
    height: 46px;
    width: 137px;
  }

  &__google {
    height: 62px;
    width: 160px;

    @media screen and (min-width: $breakPointSmallUp) {
      height: 67px;
      width: 173px;
    }
  }

  @media screen and (min-width: $breakPointSmallUp) {
    flex-flow: row nowrap;
    justify-content: space-between;
  }
}
