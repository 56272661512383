.MoovyTable {
  font-size: 14px;
  color: #4a4a4a;

  width: 100%;
  margin-bottom: 1em;

  th:first-child,
  td:first-child {
    border-left: none;
  }

  th,
  td {
    border-left: 1px solid #f4f4f5;
    line-height: 1.5;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 14px 23px;
  }

  th {
    background: rgba(216, 216, 216, 0.57);
    text-align: left;
  }

  tr {
    border-bottom: 1px solid #f4f4f5;
  }

  tr:last-child {
    border-bottom: none;
  }
}

.TablePagination {
  text-align: right;
}