.Search {
  position: relative;
  width: 360px;

  .Input {
    & > input {
      height: 35px;
      background: #fafafa;
      border: 1px solid #999999;
      box-sizing: border-box;
      border-radius: 20px;
      color: #4a4a4a;
      padding-right: 38px;
    }
    & > ::placeholder {
      color: #cecece;
    }
  }

  .Icon {
    position: absolute;
    top: 7px;
    right: 10px;
  }
}
