@import "../../styles/config";

.Input {
  display: inline-block;
  width: 100%;

  label {
    display: block;
    margin-bottom: 0.7em;
  }

  ::placeholder {
    opacity: 0.5;
  }

  &__input {
    display: inline-block;
    width: 100%;
    height: 44px;
    background: $colorPrimaryLighter;
    border: 2px solid $colorPrimaryDark;
    color: $colorPrimaryDarker;
    border-radius: 22px;
    padding-left: 22px;
    padding-right: 22px;

    &--invalid {
      border: 2px solid $colorError;
      color: $colorError;
      background: lighten($colorError, 56%);
    }
  }
  &__input:focus {
    background: $colorWhite;
    color: $colorBlack;
    outline: none;
  }
  &__input::placeholder {
    color: $colorPrimaryDarker;
  }

  &__errormsg {
    height: 14px;
    font-size: 12px;
    color: $colorError;
    text-align: right;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1;
    font-weight: normal;
  }
}
