@import "../../styles/config";

.Notification {
  padding: 20px;
  min-width: 335px;

  &__content {
    & > h4 {
      text-align: center;
      font-weight: bold;
      margin-bottom: 1em;
    }
    & > p {
      margin-bottom: 1em;
    }
  }

  &__buttonWrapper {
    text-align: center;
  }

  &__button {
    width: 200px;
  }

  &__success {
    box-shadow: 0px 0px 7px 5px rgba(8, 81, 113, 0.43);
    & h4 {
      color: $colorPrimaryDark;
    }
  }
  &__error {
    box-shadow: 0px 0px 7px 5px rgba(208, 1, 27, 0.43);
    & h4 {
      color: $colorError;
    }
  }
}
