@import "../../styles/config";

.AccountSummary {
  display: inline-block;
  width: 360px;
  color: $colorText;

  &__name,
  &__businessID {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
    line-height: 1.35;
    font-weight: bold;
  }

  &__address {
    font-size: 14px;
    line-height: 1.93;
  }

  &__table {
    color: $colorText;
    font-size: 14px;
    line-height: 1.93;
    margin-top: 16px;

    & tbody tr th {
      color: #555555;
      font-weight: bold;
      width: 110px;
      text-align: left;
    }
    & tbody tr td p {
      margin-left: 7px;
      width: calc(360px - 117px); // width of th plus margin
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
