@import "../../styles/config";

.Pagination {
  margin-bottom: 0.5em;
  font-size: 14px;
  color: #2d9cdb;
  user-select: none;
  > span {
    margin: 0 0.3em;
  }
}

.ChangePage {
  cursor: pointer;
  &:hover {
    color: $colorPrimaryLight;
  }
}

.Disabled {
  cursor: default;
}

.CurrentPageText {
  font-weight: bold;
}

.Dots {
  color: $colorDarker;
}
