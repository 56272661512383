@import "../../styles/config";

.ContactInfo {
  &__form {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
  &__formBlockContainer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: 787px;
    margin-right: 20px;

    @media screen and (min-width: $breakPointSmallUp) {
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;

      div.FormBlock.FormBlock--smallTitle {
        margin-left: 35px;
      }

      div.FormBlock.FormBlock--smallTitle > h2 {
        margin-top: 46px;
      }
    }
  }
  &__blockTitle {
    margin-bottom: 21px;
  }
  &__doubleInput {
    display: flex;
    justify-content: space-between;
  }
  &__terms {
    margin-bottom: 16px;
  }
  &FormBlock--smallTitle {
    margin-bottom: 30px;
  }
}

div.ContactInfo__doubleInput__left {
  width: 139px;
}
div.ContactInfo__doubleInput__right {
  width: 179px;
}
