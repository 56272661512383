.editAssignment {
  td.editable {
    vertical-align: bottom;
  }
  div.Input {
    &.editName input {
      height: 35px;
      width: 178px;
    }
    &.editAdditionalInfo input {
      height: 35px;
      width: 180px;
    }
    &.editMaxVehicles {
      width: 130px;
      input {
        height: 35px;
      }
    }
  }
}