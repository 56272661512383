@import "../../styles/config";

.SignupProgress {
  padding-top: 5px;

  &__title {
    color: $colorWhite;
    margin-bottom: 25px;
  }

  &__svg {
    overflow: visible;
    height: 60px;

    &__circle {
      stroke: $colorWhite;
      stroke-width: 1px;
      fill: #30acdf;

      &--active {
        fill: #70dff3;
      }

      &--clickable {
        cursor: pointer;
      }
    }
    &__balloon {
      font-weight: bold;
      font-size: 18px;
      fill: $colorWhite;
    }

    & > line {
      stroke-width: 1px;
      stroke-dasharray: 2;
      stroke: $colorWhite;
    }

    &__steptitle {
      fill: $colorWhite;
      font-size: 14px;
    }
  }
}
