@import "../../../styles/config";

.AssignmentsTable {
  flex: 1;

  & > h1 {
    margin-bottom: 16px;
  }

  &__spinner {
    text-align: center;
  }

  th.AdditionalInfoHeader {
    width: 260px;
  }
}
