@import "../../styles/config";

.BaseModal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);

  display: flex;
  justify-content: center;
}

.BaseModal {
  align-self: center;

  max-width: calc(100vw - 40px);
  max-height: calc(100vh - 40px);
  overflow: auto;
  outline: none;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.43);
  background: $colorWhite;
  opacity: 0;

  &--afterOpen {
    opacity: 1;
    transition: opacity 300ms;
  }
  &--beforeClose {
    opacity: 0;
  }
}
