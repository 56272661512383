@import "../../styles/config";

.InvoiceInfo {
  &__container {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  &__column {
    width: 747px;
    @media screen and (max-width: $breakPointSmallUp) {
      width: 100%;
    }
  }
  &__row {
    display: flex;
    width: 747px;
    justify-content: space-between;
    @media screen and (max-width: $breakPointSmallUp) {
      flex-flow: column;
      width: 100%;
    }
    .emailInput {
      width: 306px;
    }
  }
  &__centeredButton {
    text-align: center;
  }
  &__formBlockContainer {
    max-width: 747px;

    @media screen and (min-width: $breakPointSmallUp) {
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
  &__blockTitle {
    margin-bottom: 21px;
  }

  &__terms {
    margin-bottom: 16px;
  }
}

p.P {
  max-width: none;
}
div.Checkbox {
  display: block;
  margin-bottom: 1em;
}

div.InvoiceInfo__doubleInput__left {
  width: 139px;
  margin-right: 16px;
}
div.InvoiceInfo__doubleInput__right {
  width: 179px;
}
