.FormBlock {
  width: 335px;
  margin-bottom: 40px;

  &__title {
    margin-bottom: 21px;
  }

  &--smallTitle {
    .FormBlock__title {
      margin-bottom: 10px;
    }
  }
}
