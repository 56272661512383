.VoucherCard {
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  border-radius: 11px;

  .Container {
    margin: 1em;
    display: grid;
    grid-template-columns: 46px 99fr 1fr;
  }

  .LocationPin {
    width: 30px;
    height: 35px;
  }

  .Title {
    font-size: 20px;
    line-height: 1.1;
    letter-spacing: 0.4px;
    font-weight: 500;
    color: #555555;
    max-height: 44px;
    overflow: hidden;
  }

  .AfterTitle {
    grid-column-start: 2;
    grid-column-end: 2;
    font-size: 14px;
    line-height: 18px;
  }

  .Emphasis {
    font-weight: bold;
  }

  .Price {
    margin-top: 1em;
    font-style: italic;
    color: #979797;
  }
}
