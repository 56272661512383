@import "../../styles/config";

.ManageSubscription {
  color: $colorText;

  & > h1 {
    margin-bottom: 32px;
  }

  &__content {
    display: flex;
    flex-flow: row wrap;

    & > .SubscribedServiceCard {
      margin-top: 8px;
      margin-bottom: 29px;
      margin-right: 29px;
    }
  }

  &__attention {
    color: $colorError;
  }
}
